import { Injectable, NgZone } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router } from '@angular/router';
import { UserService } from '../services/user.service';

@Injectable({
    providedIn: 'root',
})
export class AuthService {

    constructor(
        public afAuth: AngularFireAuth,
        public router: Router,
        public ngZone: NgZone,
        public userService: UserService
    ) {}

    /**
     * [login - Signs in to Firebase with Email + Password]
     * @param   {string} email     [Users email address]
     * @param   {string} password  [Users password]
     * @returns {Promise<any>}
     */
    login(email: string, password: string): Promise<any> {
        return this.afAuth
            .signInWithEmailAndPassword(email, password)
            .then((result) => {
                this.afAuth.authState.subscribe((user) => {
                    if (user) {
                        this.userService.getUserAccount(user.uid).subscribe((response) => {
                            localStorage.setItem('user', JSON.stringify({ uid: user.uid }));
                            
                            // Set User
                            this.userService.setUserAccount(response);

                            // Redirect
                            this.router.navigate(['dashboard']);
                        });
                    }
                });
            })
    }

    /**
     * [logout - Signs user out of their account]
     * @returns {Promise<any>}
     */
    logout(): Promise<any> {
        return this.afAuth
            .signOut()
            .then(() => {
                localStorage.removeItem('user');
                this.router.navigate(['login']);
            });
    }

    /**
     * [isLoggedIn - Checks if user is logged in]
     * @returns {boolean}
     */
    isLoggedIn(): boolean {
        let user = JSON.parse(localStorage.getItem('user')!);
        let success = false;
    
        if(user) {
            success = true;
        }

        return success;
    }
}
