import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'comp-camera',
    templateUrl: './camera.component.html',
    styleUrls: ['./camera.component.scss'],
})
export class CameraComponent implements OnInit {

    constructor() {}

    ngOnInit(): void {

    }

    onCaptureClick(): void {

    }

    onCanceClick(): void {

    }
}
