import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard'

const routes: Routes = [
    {
        path: 'dashboard',
        canActivate: [AuthGuard],
        loadChildren: () =>
            import('./pages/dashboard/dashboard.module').then((m) => m.DashboardPageModule),
    },
    {
        path: 'auctions',
        canActivate: [AuthGuard],
        loadChildren: () =>
            import('./pages/auctions/auctions.module').then((m) => m.AuctionsPageModule),
    },
    {
        path: 'auction/:id',
        canActivate: [AuthGuard],
        loadChildren: () =>
            import('./pages/auction/auction.module').then((m) => m.AuctionPageModule),
    },
    {
        path: 'inventory',
        canActivate: [AuthGuard],
        loadChildren: () =>
            import('./pages/inventory/inventory.module').then((m) => m.InventoryPageModule),
    },
    {
        path: 'cataloguing',
        canActivate: [AuthGuard],
        loadChildren: () =>
            import('./pages/cataloguing/cataloguing.module').then((m) => m.CataloguingPageModule),
    },
    {
        path: 'layout',
        canActivate: [AuthGuard],
        loadChildren: () =>
            import('./pages/layout/layout.module').then((m) => m.LayoutPageModule),
    },
    {
        path: 'bots',
        canActivate: [AuthGuard],
        loadChildren: () =>
            import('./pages/bots/bots.module').then((m) => m.BotsPageModule),
    },
    {
        path: 'bot/:id',
        canActivate: [AuthGuard],
        loadChildren: () =>
            import('./pages/bot/bot.module').then((m) => m.BotPageModule),
    },
    {
        path: 'users',
        canActivate: [AuthGuard],
        loadChildren: () =>
            import('./pages/users/users.module').then((m) => m.UsersPageModule),
    },
    {
        path: 'user/:id',
        canActivate: [AuthGuard],
        loadChildren: () =>
            import('./pages/user/user.module').then((m) => m.UserPageModule),
    },
    {
        path: 'login',
        loadChildren: () =>
            import('./pages/login/login.module').then((m) => m.LoginPageModule),
    },
    {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full',
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
