import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class UserService {
    // User Unique ID
    id: number = 0;

    // Firebase UID
    uid: string = '';

    // Contact Info
    email: string = '';
    phone: string = '';

    // Name
    firstName: string = '';
    lastName: string = '';
    fullName: string = '';

    // Other Properties
    channel: string = '';
    admin: boolean = false;
    role: number = 1;
    token: string = '';

    constructor(private http: HttpClient) {}

    /**
     * [setUserAccount - Sets the authenticated users data]
     * @returns {void}
     */
    setUserAccount(response: any): void {
        this.id = response.user.id;
        this.uid = response.user.uid;
        
        // Personal Info
        this.firstName = response.user.first_name;
        this.lastName = response.user.last_name;
        this.fullName = `${this.firstName} ${this.lastName}`
        
        // Contact
        this.email = response.user.email;
        this.phone = response.user.phone;

        // Other
        this.admin = response.user.admin;
        this.channel = response.user.email;
        this.token = response.user.token;
        this.role = response.user.role;
    }

    /**
     * [getUserAccount - Gets users account details]
     * @param   {string} uid [Users Firebase UID]
     * @returns {Observable<any>}
     */
    getUserAccount(uid: string): Observable<any> {
        return this.http.get<Array<Object>>(
            `${environment.api_url}/accounts/${uid}`
        );
    }

    /**
     * [getUsers - Gets User List]
     * @returns {Observable<any>}
     */
    getUsers(pagination: any = {}): Observable<any> {
        let params = new HttpParams({
            fromObject: {
                page: pagination.page
            }
        });

        return this.http.get<Array<Object>>(
            `${environment.api_url}/users`,
            { params : params }
        );
    }

    /**
     * [getUser - Gets User Details]
     * @param   {string} id [User ID]
     * @returns {Observable<any>}
     */
    getUser(id: string): Observable<any> {
        return this.http.get<Array<Object>>(
            `${environment.api_url}/user/${id}`
        );
    }

    /**
     * [createUser - Create New User]
     * @param   {any} user [User Data]
     * @returns {Observable<any>}
     */
    createUser(user: any): Observable<any> {
        let data = new FormData();

        data.append('id', user.id);
        data.append('uid', user.uid);
        data.append('first_name', user.first_name);
        data.append('last_name', user.last_name);
        data.append('phone', user.phone);
        data.append('email', user.email);
        data.append('password', user.password);
        data.append('admin', user.admin);
        data.append('role', user.role);

        return this.http.post<Object>(
            `${environment.media_url}/user/create`,
            data
        );
    }

    /**
     * [updateUser - Update User Details]
     * @param   {any} user [User Data]
     * @returns {Observable<any>}
     */
    updateUser(user: any): Observable<any> {
        let data = new FormData();
        
        data.append('id', user.id);
        data.append('uid', user.uid);
        data.append('first_name', user.first_name);
        data.append('last_name', user.last_name);
        data.append('phone', user.phone);
        data.append('email', user.email);
        data.append('password', user.password);
        data.append('admin', user.admin);
        data.append('role', user.role);

        return this.http.post<Object>(
            `${environment.media_url}/user/${user.id}/update`,
            data
        );
    }

    /**
     * [deleteUser - Delete User]
     * @param   {any} id [User ID]
     * @returns {Observable<any>}
     */
    deleteUser(id: string): Observable<any> {
        const data = {};

        return this.http.post<Object>(
            `${environment.media_url}/user/${id}/delete`,
            data
        );
    }
    /**
     * [getUserActivities - Gets User Activities]
     * @returns {Observable<any>}
     */
    getUserActivities(id: any, pagination: any = {}): Observable<any> {
        let params = new HttpParams({
            fromObject: {
                page: pagination.page
            }
        });

        return this.http.get<Array<Object>>(
            `${environment.api_url}/user/${id}/activities`,
            { params : params }
        );
    }
}
