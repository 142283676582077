// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    //api_url: 'https://api.encoreauctions.ca/api',
    //api_url: 'http://127.0.0.1:5000/api',
    //media_url: 'http://127.0.0.1:5000/api',
    api_url: 'https://b2daexwpt6.execute-api.ca-central-1.amazonaws.com/dev/api',
    media_url: 'https://api.encoreauctions.ca/api',
    app_token: 'x8IJt2csoU5q6eJUa1yr6FGBqZkWb6l9RJRr9RvlTxUSOMH6JpWs022gEiLoRIRK',
    pusher: {
        key: 'c08462f9253beb4fddc8',
        cluster: 'us2',
    },
    firebase: {
        apiKey: "AIzaSyCg2tTWKxKGpBuTC9FO4Jy8XqOCjcATdUk",
        authDomain: "encore-auctions.firebaseapp.com",
        projectId: "encore-auctions",
        storageBucket: "encore-auctions.appspot.com",
        messagingSenderId: "981570615579",
        appId: "1:981570615579:web:3a8e8ae44189fe00603bbe"
    }
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
