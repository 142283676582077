// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host comp-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}`, "",{"version":3,"sources":["webpack://./src/app/app.component.scss"],"names":[],"mappings":"AACI;EACI,kBAAA;EACA,SAAA;EACA,OAAA;EACA,QAAA;AAAR","sourcesContent":[":host {\r\n    comp-footer {\r\n        position: absolute;\r\n        bottom: 0;\r\n        left: 0;\r\n        right: 0;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
