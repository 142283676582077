import { Component } from '@angular/core';
import { AuthService } from './services/auth.service';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { UserService } from './services/user.service';
import { BehaviorSubject, Observable } from 'rxjs';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
})
export class AppComponent {

    // Ready for page load 
    _isReady: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    // Is Authenticated
    _isAuthenticated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    get isAuthenticated(): Observable<boolean> {
        return this._isAuthenticated.asObservable();
    }

    get isReady(): Observable<boolean> {
        return this._isReady.asObservable();
    }

    constructor(
        private authService: AuthService,
        private route: ActivatedRoute,
        private router: Router,
        private userService: UserService,
        //private pusher: PusherService
    ) {
        this.router.events.subscribe((event) => {
            if(event instanceof NavigationStart) {
                document.body.className = '';

                // Add Class
                document.body.classList.add(
                    'body' + event.url.replace('/', '-')
                );

                if(event.url.includes('login')) {
                    this._isAuthenticated.next(false);
                } else {
                    this._isAuthenticated.next(true);
                }
            }
        });

        this.getAuthenticatedUser();
    }

    /**
     * [getAuthenticatedUser - Get the authenticated user]
     * @returns {Promise}
     */
    async getAuthenticatedUser() {
        let user = JSON.parse(localStorage.getItem('user')!);

        if(user) {
            await this.userService.getUserAccount(user.uid).subscribe((response) => {
                this.userService.setUserAccount(response);
                this._isAuthenticated.next(true);
                this._isReady.next(true);
            });
        } else {
            this._isReady.next(true);
        }
    }

}
